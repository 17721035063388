margin-top:15px<template>
	<!-- 专业管理 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;height: 670px;">
			<div class="title">对外门户展示信息设置</div>
			<div class="line"></div>
            <el-row style="font-size:15px">
                模块列表
            </el-row>
            <el-row style="margin-top:15px">
                <el-col :span="4">
                   <el-checkbox  v-model="dataForm.weekreportFlg" class="sbox" label="周报" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.monthreportFlg" class="sbox" label="月报" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.dayreportFlg" class="sbox" label="日报" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.contactFlg" class="sbox" label="监理联系单" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.noticeFlg" class="sbox" label="监理通知单" :disabled="isDisabled()"></el-checkbox>
                </el-col>
            </el-row>
            <el-row style="margin-top:15px">
                <el-col :span="4">
                   <el-checkbox  v-model="dataForm.specialreportFlg" class="sbox" label="专题报告" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.finishworkFlg" class="sbox" label="完工资料" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.safetyFlg" class="sbox" label="安全监督检查记录" :disabled="isDisabled()"></el-checkbox>
                </el-col>
            </el-row>
            <el-row style="margin-top:15px">
                <el-col :span="4">
                   <el-checkbox  v-model="dataForm.inspectionFlg" class="sbox" label="巡检记录" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.testFlg" class="sbox" label="报验记录" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.onsiteFlg" class="sbox" label="旁站记录" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.meetFlg" class="sbox" label="监理会议" :disabled="isDisabled()"></el-checkbox>
                </el-col>
                <el-col :span="4">
                    <el-checkbox v-model="dataForm.contractFlg" class="sbox" label="业主方合同" :disabled="isDisabled()"></el-checkbox>
                </el-col>
            </el-row>
            <el-row style="margin-top:15px">
                <el-col :span="4">
                   <el-checkbox v-model="dataForm.designFlg" class="sbox" label="设计文件审查记录（开发中）" disabled></el-checkbox>
                </el-col>
            </el-row>
            <el-row class="formBtnGroup" style="margin-top: 360px;" v-if="dataForm.showButton">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import $common from "@/utils/common.js"
export default {
	data() {
		return {
            dataForm: {},
            disabled : false
		};
	},
	components: {
	},
	mounted() {
        this.info()
	},
	methods: {
        isDisabled() {
            if(this.dataForm.showButton != undefined) {
                if(this.dataForm.showButton) {
                    return false;
                } else {
                    return true;
                }
            }
        },
		//获取数据
		info() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/webmoduleshow/info/" + $common.getItem("workNo"),
				method: 'get',
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
                    this.$set(this.dataForm, 'recNo', data.body.recNo )
                    this.$set(this.dataForm, 'workNo', data.body.workNo)
                    this.$set(this.dataForm, 'weekreportFlg', data.body.weekreportFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'monthreportFlg', data.body.monthreportFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'dayreportFlg', data.body.dayreportFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'contactFlg', data.body.contactFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'noticeFlg', data.body.noticeFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'specialreportFlg', data.body.specialreportFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'finishworkFlg', data.body.finishworkFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'safetyFlg', data.body.safetyFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'inspectionFlg', data.body.inspectionFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'testFlg', data.body.testFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'onsiteFlg', data.body.onsiteFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'meetFlg', data.body.meetFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'contractFlg', data.body.contractFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'designFlg', data.body.designFlg == 0 ? false : true)
                    this.$set(this.dataForm, 'showButton', data.body.showButton == 0 ? false : true)
				}
			})
		},
        dataFormSubmit() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/webmoduleshow/update",
				method: 'post',
				data: this.$http.adornData({
                    recNo: this.dataForm.recNo,
                    workNo : this.dataForm.workNo,
                    weekreportFlg : this.dataForm.weekreportFlg ? '1' : '0',
                    monthreportFlg : this.dataForm.monthreportFlg ? '1' : '0',
                    dayreportFlg : this.dataForm.dayreportFlg ? '1' : '0',
                    contactFlg : this.dataForm.contactFlg ? '1' : '0',
                    noticeFlg : this.dataForm.noticeFlg ? '1' : '0',
                    specialreportFlg : this.dataForm.specialreportFlg ? '1' : '0',
                    finishworkFlg : this.dataForm.finishworkFlg ? '1' : '0',
                    inspectionFlg : this.dataForm.inspectionFlg ? '1' : '0',
                    safetyFlg : this.dataForm.safetyFlg ? '1' : '0',
                    testFlg : this.dataForm.testFlg ? '1' : '0',
                    onsiteFlg : this.dataForm.onsiteFlg ? '1' : '0',
                    meetFlg : this.dataForm.meetFlg ? '1' : '0',
                    contractFlg : this.dataForm.contractFlg ? '1' : '0',
                    designFlg : this.dataForm.designFlg ? '1' : '0',
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.info();
                        }
                    });
                }
            })
        }
	}
};
</script>
<style lang="scss" scoped="scoped">
.sbox {
    color: black;
}

.sbox::v-deep .el-checkbox__inner {
    width: 17px;
    height: 17px;
}

.sbox::v-deep .el-checkbox__label {
    font-size: 14px;
}

.sbox::v-deep .el-checkbox__inner::after{
    margin-left: 2px;
    margin-top: 1px;
 }
</style>
