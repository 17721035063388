import { render, staticRenderFns } from "./webmoduleshow.vue?vue&type=template&id=20cb1002&scoped=true&"
import script from "./webmoduleshow.vue?vue&type=script&lang=js&"
export * from "./webmoduleshow.vue?vue&type=script&lang=js&"
import style0 from "./webmoduleshow.vue?vue&type=style&index=0&id=20cb1002&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20cb1002",
  null
  
)

export default component.exports